@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  font-family: 'poppins', 'sans-serif';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content:center;
  align-items: center;
  background: linear-gradient(to right, lightblue, blue);
}

.App {
  text-align: center;
}

h1 {
  color: white;
  margin-bottom: 0.5rem;
  font-size: 1.75rem;
}

.NotesWrapper {
  background: rgb(71, 13, 71);
  margin-top: 5rem;
  padding: 2rem;
  border-radius: 5px;
}

.NotesForm {
  width: 100%;
  margin-bottom: 1rem;
}

.notes-input {
  outline: none;
  background: none;
  border: 1px solid purple;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 300px;
  color: white;
}

.notes-input::placeholder {
  color: grey;
}

.notes-btn {
  background: purple;
  color: white;
  border: none;
  padding: 0.55rem;
  cursor: pointer;
}

.Notes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: purple;
  color: white;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.fa-trash {
  margin-left: 0.75rem;
}

.incompleted {
  color:white;
  cursor: pointer;
}

.completed{
  color:rgb(117, 42, 117);
  text-decoration: line-through;
  cursor: pointer;
}

.edit-icon,
.delete-icon {
  cursor: pointer;
}